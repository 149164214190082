(function ($) {
	"use strict";
   var Brw_Frontend = {
   	init: function () {
   		this.brw_datepicker();
        this.submit_button();
        this.ova_collapsed();
        this.ova_load_name_product();
        this.ova_load_tag_product();
        this.ova_field_search_changed();
        this.ova_get_time_to_book_start();
        this.ova_get_time_to_book_end();
        this.ova_change_pickup_location_transport();
        this.ova_change_pick_up_off_other_location();
        this.ova_startdate_perido_time();
        this.ova_ajax_show_total();
        this.ova_ajax_show_total_after_load();
        this.ova_search_ajax();
        this.ova_guestspicker();
    },

    ova_change_pickup_location_transport: function() {

        $('.ovabrw-booking').on('change', 'select[name="ovabrw_pickup_loc"].ovabrw-transport ', function(){

            $(this).closest('.wrap-item').find('select[name="ovabrw_pickoff_loc"]').empty();

            var pickup_loc = $(this).val()
            var item_loc = $(this).find('option[value="'+pickup_loc+'"]').data('item_loc');

            var html_option_dropoff = '';
            if( item_loc ) {
                item_loc.forEach(function(item){
                    if( item ) {
                        html_option_dropoff += '<option value="'+item+'" >'+item+'</option>';
                    }
                });

                    
                if( ! html_option_dropoff ) {
                    html_option_dropoff = '<option value="">Select Location</option>';
                }

            } else {
                html_option_dropoff = '<option value="">Select Location</option>';
            }

            $(this).closest('.wrap-item').find('select[name="ovabrw_pickoff_loc"]').append(html_option_dropoff);

        });
    },

    ova_change_pick_up_off_other_location: function() {

        $('.ovabrw-booking').on('change', 'select[name="ovabrw_pickup_loc"] ', function(){
            var value_pickup = $(this).val();
            if( value_pickup === 'other_location' ) {
                $(this).closest('.rental_item').find('.ovabrw-other-location').append('<input style="margin-top: 10px;" name="ovabrw_pickup_loc" type="text" placeholder="Other Location">');
            } else {
                $(this).closest('.rental_item').find('.ovabrw-other-location').empty();
            }
        });

        $('.ovabrw-booking').on('change', 'select[name="ovabrw_pickoff_loc"] ', function(){
            var value_pickup = $(this).val();
            if( value_pickup === 'other_location' ) {
                $(this).closest('.rental_item').find('.ovabrw-other-location').append('<input style="margin-top: 10px;" name="ovabrw_pickoff_loc" type="text" placeholder="Other Location">');
            } else {
                $(this).closest('.rental_item').find('.ovabrw-other-location').empty();
            }
        });

    },

    ova_get_time_to_book_start: function(time_to_book) {
        
        var time_to_book_arr = [];
        var time_to_book_general = [];
        if(typeof brw_time_to_book_general_calendar !== 'undefined' ){
            time_to_book_general =  brw_time_to_book_general_calendar.replace(/ /g,'').split( ',' );
        }

        if( typeof time_to_book == 'undefined' ) {
            time_to_book_arr = time_to_book_general;
        } else if( time_to_book == 'no' ) {
            time_to_book_arr = [];
        } else {
            time_to_book_arr =  time_to_book.replace(/ /g,'').split( ',' );
        }

        return time_to_book_arr;

    },

    ova_get_time_to_book_end: function(time_to_book) {

        var time_to_book_arr = []; 
        var time_to_book_endate_general = [];
        if(typeof brw_time_to_book_general_calendar_endate !== 'undefined' ){
            time_to_book_endate_general =  brw_time_to_book_general_calendar_endate.replace(/ /g,'').split( ',' );
        }

        
        if( typeof time_to_book == 'undefined' ) {
            time_to_book_arr = time_to_book_endate_general;
        } else if( time_to_book == 'no' ) {
            time_to_book_arr = [];
        } else {
            time_to_book_arr =  time_to_book.replace(/ /g,'').split( ',' );
        }

        return time_to_book_arr;

    },


    ova_field_search_changed: function() {

        /* Attribute Changed */
        $('.ovabrw_search select[name="ovabrw_attribute"]').on('change', function(){

            $(this).closest('.wrap_content').children('.s_field.ovabrw-value-attribute').css('display', 'none');

            var val = $(this).val();
            if( val ){
                $(this).closest('.wrap_content').children('.s_field#' + val).css('display', 'inline-block');    
            }
            
        });

        
        /* Cat Changed */
        $('.ovabrw_search select[name="cat"]').on('change', function(){

            var form = $(this).closest('form');
            var cat_val = $(this).val();

            ovas_show_cus_tax( form, cat_val );

        });

        if(  $('.ovabrw_search').length ){
            ovas_show_cus_tax(  $('.ovabrw_search'), '' );
        }
        

        /* Cat when the page loaded*/
        if( $( '.ovabrw_search select[name="cat"]' ).length ){

            var form = $('form.ovabrw_search');
            var cat_val = $( 'form.ovabrw_search select[name="cat"]' ).val();
            if( cat_val ){
                ovas_show_cus_tax( form, cat_val );    
            }
            
        }
        


    },

    ova_load_name_product: function() {

        $('.ovabrw_wd_search input[name="ovabrw_name_product"]').autocomplete({
          source: function(request, response){
             $.ajax({
                url: ajax_object.ajax_url,
                type: 'POST',
                dataType: "json",
                data: {
                   action: 'ovabrw_load_name_product',
                   keyword: request.term,
                },
                success:function(data) {
                   response(data);
                },

             })
          },
          delay: 0,
       });

    },

    ova_load_tag_product: function() {

        $('.ovabrw_wd_search input[name="ovabrw_tag_product"]').autocomplete({
          source: function(request, response){
             $.ajax({
                url: ajax_object.ajax_url,
                type: 'POST',
                dataType: "json",
                data: {
                   action: 'ovabrw_load_tag_product',
                   keyword: request.term,
                },
                success:function(data) {

                   response(data);
                },

             })
          },
          delay: 0,
       });
    },

    brw_datepicker: function() {
        // get firstday
        var firstday = $('.ovabrw_datetimepicker').data('firstday');

        /* Calendar language */
        if ( typeof brw_lang_general_calendar !== 'undefined' ) {
            $.datetimepicker.setLocale( brw_lang_general_calendar );
        }

        var disweek_arr = '';
        if ( typeof brw_disable_week_day !== 'undefined' ) {
            disweek_arr = brw_disable_week_day.split(',').map(function(item) {
                return parseInt(item, 10);
            });
        }

        var allowTimes = '';
        if ( typeof brw_time_to_book_general_calendar !== 'undefined' ) {
            allowTimes =  brw_time_to_book_general_calendar.replace(/ /g,'').split( ',' );
        }

        var data_step = 30;
        if ( typeof brw_data_step !== 'undefined' ) {
            data_step = brw_data_step;
        }

        var time_format = '';
        if ( typeof brw_format_time !== 'undefined' ) {
            time_format = brw_format_time;
        }
        
        var date_format = '';
        if ( typeof brw_date_format !== 'undefined' ) {
            date_format = brw_date_format;
        }

        switch ( date_format ) {
            case 'd-m-Y':
                date_format = 'DD-MM-Y';
                break;
            case 'm/d/Y':
                date_format = 'MM/DD/Y';
                break;
            case 'Y/m/d':
                date_format = 'Y/MM/DD';
                break;
            case 'Y-m-d':
                date_format = 'Y-MM-DD';
                break;
            default:
                date_format = 'DD-MM-Y';
        }
        
        
        var today = new Date();

        // Setup Mask date for datetimepicker via moment library
        $.datetimepicker.setDateFormatter({
            parseDate: function (date, format) {
                var d = moment(date, format);
                return d.isValid() ? d.toDate() : false;
            },
            
            formatDate: function (date, format) {
                return moment(date).format(format);
            },
        });

        // Check mobile
        if ( $(window).width() <= 1024 ) {
            $('.ovabrw_datetimepicker').datetimepicker({closeOnTimeSelect: false}); // Not closed when selecting time.
        }

        $('.ovabrw_datetimepicker').each( function() {
            /* Disable Date */
            var disabledDates = [];
            var order_time_arr_new = [];
            var order_time = $(this).attr( 'order_time' );

            if( order_time ){
                order_time_arr_new = JSON.parse( order_time );
            }
           
            order_time_arr_new.forEach(function(item, index){
                if ( item.hasOwnProperty('rendering') ) {
                    if ( item.start_v2 ) {
                        disabledDates.push(item.start_v2);
                    }
                }
            });

            // Default Hour Start
            var defaultTime = $(this).attr('default_hour');

            var datePickerOptions = {};

            if ( $(this).hasClass('no_time_picker') ) {
                datePickerOptions = {
                    scrollInput: false,
                    dayOfWeekStart: firstday,
                    minDate: today,
                    disabledWeekDays: disweek_arr,
                    format: date_format,
                    formatDate: date_format,
                    allowTimes: false,
                    timepicker: false,
                    autoclose: true,
                    disabledDates: disabledDates,
                    defaultTime: false
                };
            } else {
                datePickerOptions = {
                    scrollInput: false,
                    dayOfWeekStart: firstday,
                    minDate: today,
                    disabledWeekDays: disweek_arr,
                    format: date_format+' '+time_format,
                    formatDate: date_format,
                    formatTime:time_format,
                    step: data_step, 
                    allowTimes: allowTimes,
                    timepicker:true,
                    autoclose: true,
                    disabledDates: disabledDates,
                    defaultTime: defaultTime
                }
            }

            $(this).datetimepicker(datePickerOptions);
        });

    	$('.ovabrw_datetimepicker.ovabrw_start_date').on('click', function(e) {
            e.preventDefault();

            var that = $(this);
            that.val('');

            // Default Hour Start
            var defaultTime = $(this).attr('default_hour');

            var time_format = '';
            if ( typeof brw_format_time !== 'undefined' ) {
                time_format = brw_format_time;    
            }

            var timepicker = $(this).attr('timepicker');
            if ( timepicker == 'true' ) {
                timepicker = true;
            } else {
                timepicker = false;
            }

            var time_to_book    = $(this).attr('time_to_book');
            var allowTimes      = Brw_Frontend.ova_get_time_to_book_start(time_to_book);
            var check_out       = $(this).closest('form').find('input.ovabrw_end_date');

    		var datePickerOptions = {};

            if ( $(this).hasClass('no_time_picker') || (allowTimes.length == 0) || timepicker == false ) {
                datePickerOptions = {
                    dayOfWeekStart: firstday,
                    minDate: today,
                    disabledWeekDays: disweek_arr,
                    autoclose: true,
                    step: data_step,
                    format: date_format,    
                    formatDate: date_format,
                    defaultTime: false,
                    allowTimes: false,
                    timepicker: false,
                    onSelectDate:function(current_time, $input) {
                        if ( current_time && check_out.length > 0 ) {
                            var end_date    = current_time.setDate(current_time.getDate() + 1);
                            var checkOutStr = ovaDateFormat( end_date, date_format );
                            check_out.val(checkOutStr);
                        }
                    }
                };
            } else {
                datePickerOptions = {
                    dayOfWeekStart: firstday,
                    minDate: today,
                    disabledWeekDays: disweek_arr,
                    autoclose: true,
                    step: data_step,
                    format: date_format+' '+time_format,    
                    formatDate: date_format,
                    formatTime: time_format,
                    defaultTime: defaultTime,
                    allowTimes: allowTimes,
                    timepicker: timepicker
                }
            }

            $(this).datetimepicker(datePickerOptions);
        });


        $('.ovabrw_datetimepicker.ovabrw_end_date').on('click',function() {
            var that = $(this);
            that.val('');

            var timepicker = $(this).attr('timepicker');
            if ( timepicker == 'true' ) {
                timepicker = true;
            } else {
                timepicker = false;
            }

            var time_format = '';
            if ( typeof brw_format_time !== 'undefined' ) {
                time_format = brw_format_time;    
            }
            
            var time_to_book    = $(this).attr('time_to_book');
            var allowTimes      = Brw_Frontend.ova_get_time_to_book_end(time_to_book);
            var defaultTime     = $(this).attr('default_hour');

            if ( $(this).hasClass('no_time_picker') || (allowTimes.length == 0) || timepicker == false ) {
                timepicker = false;
                time_format = '';
                defaultTime = false;
            } 

            /* Get Min Time */
            var min = $(this).closest('form').find('.ovabrw_start_date').val();
            if ( ! min ) {
                min = $('.ovabrw_start_date.ovabrw_datetimepicker').val();
            }

            var datePickerOptions = {};

            if ( $(this).hasClass('no_time_picker') || (allowTimes.length == 0) || timepicker == false ) {
                datePickerOptions = {
                    dayOfWeekStart: firstday,
                    minDate: min ? min : today,
                    startDate: min ? min : today,
                    disabledWeekDays: disweek_arr,
                    autoclose: true,
                    format: date_format,    
                    formatDate: date_format,
                    defaultTime: false,
                    allowTimes: false,
                    timepicker: false,
                    onSelectDate:function(current_time, $input){}
                };
            } else {
                datePickerOptions = {
                    dayOfWeekStart: firstday,
                    disabledWeekDays: disweek_arr,
                    autoclose: true,
                    step: data_step,
                    minDate: min ? min : today,
                    format: date_format+' '+time_format,
                    formatDate: date_format,
                    formatTime:time_format,
                    defaultTime: defaultTime,
                    allowTimes: allowTimes,
                    timepicker:timepicker
                }
            }

            $(this).datetimepicker(datePickerOptions);
        });

        //date picker period (date, no time)
        $('.ovabrw_datetimepicker.date_picker_period').each(function(){
            
            var hour_default = $(this).data('hour_default');
            var time_step = $(this).data('time_step');         

            var datePickerOptions = {
                dayOfWeekStart: firstday,
                minDate: today,
                format: date_format,
                timepicker:false,
                
            }
            $(this).datetimepicker(datePickerOptions);
        });

        $('.ovabrw_datetimepicker.no_time_picker').each(function(){
            var datePickerOptions = {
                timepicker:false,
            }
            $(this).datetimepicker(datePickerOptions);
        });

        function ovaDateFormat( milliseconds, date_format ) {
            var date = new Date(milliseconds);

            var day = date.getDate();
            if ( day < 10 ) {
                day = '0'+day;
            }

            var month = date.getMonth()+1;
            if ( month < 10 ) {
                month = '0'+month;
            }

            var year = date.getFullYear();

            switch( date_format ) {
                case 'DD-MM-Y':
                    return day +'-'+ month +'-'+year;
                    break;
                case 'MM/DD/Y':
                    return month +'/'+ day +'/'+ year;
                    break;
                case 'Y/MM/DD':
                    return year +'/'+ month +'/'+ day;
                    break;
                case 'Y-MM-DD':
                    return year +'-'+ month +'-'+ day;
                    break;
                default:
                    return day +'-'+ month +'-'+ year;
            }
        }
    },

    submit_button: function() {
    	$('.ovabrw_btn_submit').on('click', function(e){
			var content_required = $(this).closest('.ovabrw_search').data('mesg_required');
			var flag = true;

			$(this).closest('.ovabrw_search').find('.content .wrap-error').empty();
            var class_require = $(this).closest('.form_ovabrw').find('.required');
            class_require.each(function(){
				if ( ! $(this).val() ) {
					flag = false;
					$(this).parent('.content').children('.wrap-error').append('<p class="error">'+content_required+'</p>');
				}
			});
			if ( ! flag ) {
				e.preventDefault();
				return false;
			}
		});

        /* Booking Form submit */
        $('.ovabrw_booking_form button.submit').on('click', function(e) {
            e.preventDefault();
            var form = $(this);
            var flag = true;

            $(this).closest('.ovabrw_booking_form').find('.error_item').css('display', 'none');
            $(this).closest('.ovabrw_booking_form').find('.required').each(function(){
                if ( !$(this).val() ) {
                    flag = false;

                    $(this).closest('.rental_item').children('.error_item').css('display', 'block');

                    if ( $(this).parent('.period_package').length > 0 ) {
                        $(this).parent('.period_package').parent('.rental_item').children('.error_item').css('display', 'block');
                    }
                }
            });

            // reCAPTCHA
            var recaptchaToken = $(this).closest('form').find('input[name="ovabrw-booking-recaptcha-token"]');
            if ( recaptchaToken.length > 0 && !recaptchaToken.val() ) {
                $(this).closest('form').find('.booking-error').html('').append(ovabrwRECAPTCHA.required).show();
                flag = false;
            }

            if ( !flag ) {
                e.preventDefault();
                return false;
            }

            // Search form
            var booking_form = $(this).closest('.booking_form');

            if ( booking_form.length > 0 && flag == true ) {
                var services = [];
                var obj_resource, obj_service;

                var room_id     = booking_form.find('input[name="car_id"]').val();
                var check_in    = booking_form.find('input[name="ovabrw_pickup_date"]').val();
                var check_out   = booking_form.find('input[name="ovabrw_pickoff_date"]').val();
                var adults      = booking_form.find('input[name="ovabrw_adults"]').val();
                var childrens   = booking_form.find('input[name="ovabrw_childrens"]').val();
                var rooms       = booking_form.find('input[name="ovabrw_number_vehicle"]').val();

                booking_form.find( "select[name='ovabrw_service[]']" ).each( function() {
                    if( $(this).val() ){
                        services.push($(this).val());    
                    }
                });
                
                // Convert to object
                obj_service     = $.extend({}, services ) ;
                
                $.ajax({
                    url: ajax_object.ajax_url,
                    type: 'POST',
                    data: ({
                        action: 'ovabrw_search_ajax_validation_booking_form',
                        room_id: room_id,
                        check_in: check_in,
                        check_out: check_out,
                        adults: adults,
                        childrens: childrens,
                        rooms: rooms,
                        services: JSON.stringify( obj_service ),
                    }),
                    success: function(response){
                        var result = $.parseJSON( response );
                        var booking_error = booking_form.find('.booking-error');
                        if ( result['error'] == true ) {
                            var message = result['message'];
                            booking_error.show().html(message);
                            return false;
                        } else {
                            booking_error.html('');
                            form.closest('form').submit();
                        }
                    },
                });
            }
        });

        /* Booking Form submit */
        $('.request_booking button.submit').on('click', function(e){
            var flag = true;

            $(this).closest('.request_booking').find('.error_item').css('display', 'none');
            $(this).closest('.request_booking').find('.required').each( function() {
                if ( !$(this).val() ) {
                    flag = false;

                    $(this).parent('.rental_item').children('.error_item').css('display', 'block');

                    if ( $(this).parent('.period_package').length > 0 ) {
                        $(this).parent('.period_package').parent('.rental_item').children('.error_item').css('display', 'block');
                    }
                }
            });

            // reCAPTCHA
            var recaptchaToken = $(this).closest('form').find('input[name="ovabrw-request-recaptcha-token"]');
            if ( recaptchaToken.length > 0 && !recaptchaToken.val() ) {
                flag = false;

                $(this).closest('form').find('.request-error').html('').append(ovabrwRECAPTCHA.required).show();
            }

            if ( !flag ) {
                e.preventDefault();
            }
        });
    },

    ova_collapsed: function() {

        //open popup
        $('.ovabrw_open_popup').off().on('click', function(e){
            e.preventDefault();
            $(this).siblings('.popup').css('display', 'block');
        });

        //close popup
        $('.popup-close').on('click', function(e){
            e.preventDefault();
            $(this).parent('.popup-inner').parent('.popup').css('display', 'none');
        });

        $('.popup-close-2').on('click', function(e){
            e.preventDefault();
            $(this).parent('.close_discount').parent('.popup-inner').parent('.popup').css('display', 'none');
        });

        // close popup ( booking form )
        $(window).click( function(e) {
            if ( e.target.className == 'modal-container' ) {
                var modal_container = $(document).find('.ovabrw-search-ajax .modal-container');
                modal_container.hide();
            }
        });

    },

    ova_startdate_perido_time: function() {
        /* Choose pickup date in period time */
        $( 'body' ).on( 'change', '.startdate_perido_time', function(){

            var that = $(this);

            var startdate = that.val();
            var post_id = that.data('pid');

            that.closest('form').find( 'select[name="ovabrw_period_package_id"]' ).attr( 'disabled', 'disabled' );

             $.ajax({
                url: ajax_object.ajax_url,
                type: 'POST',
                data: ({
                    action: 'ovabrw_get_package_by_time',
                    startdate: startdate,
                    post_id: post_id
                }),
                success: function(response){

                    that.closest('form').find( 'select[name="ovabrw_period_package_id"] option ' ).remove();

                    var option = '';
                    var option_obj = $.parseJSON( response );

                    if(option_obj){
                        $.each(option_obj, function(key, value) {
                            option += '<option value="'+ key + '">' + value + '</option>';  
                        });
                    }

                    if( option ) {
                        that.closest('form').find( 'select[name="ovabrw_period_package_id"] ' ).append(option);
                    }
                    if ( that.closest('form').find( 'select[name="ovabrw_period_package_id"] ' ).hasClass("select2-hidden-accessible") ) {
                        that.closest('form').find( 'select[name="ovabrw_period_package_id"] ' ).select2();
                    }

                    that.closest('form').find( 'select[name="ovabrw_period_package_id"] ' ).prop("disabled", false);
                    
                },
            });

        });
    },

    ova_ajax_show_total: function(){


        $( 'body' ).on( 'change', '.booking_form', function(){

            Brw_Frontend.ova_ajax_show_total_after_load($(this));

        });

        $('.booking_form').each( function() {
            var that = $(this);
            var check_in    = that.find('input[name="ovabrw_pickup_date"]').val();
            var check_out   = that.find('input[name="ovabrw_pickoff_date"]').val();
            
            if ( check_in && check_out ) {
                Brw_Frontend.ova_ajax_show_total_after_load(that);
            }
        });

    },

    ova_ajax_show_total_after_load: function( that = null ) {

        var pickup_loc, dropoff_loc, pickup_date, dropoff_date, quantity, resources, services, id, package_id, deposit, ovabrw_adults, ovabrw_childrens;
        var resources = [];
        var services = [];
        var obj_resource, obj_service;

        if ( that != null ) {
            var current_form = that;

            current_form.find( '.ajax_loading' ).addClass('active');
            current_form.find( '.booking-error' ).html('').hide();
            
            if( current_form.find('input[name="car_id"]').val() ){
                id = current_form.find('input[name="car_id"]').val();
            }

            if( current_form.find('select[name="ovabrw_pickup_loc"]').val() ){
                pickup_loc = current_form.find('select[name="ovabrw_pickup_loc"]').val();
            }

            if( current_form.find('select[name="ovabrw_pickoff_loc"]').val() ){
                dropoff_loc = current_form.find('select[name="ovabrw_pickoff_loc"]').val();
            }

            if( current_form.find('input[name="ovabrw_pickup_date"]').val() ){
                pickup_date = current_form.find('input[name="ovabrw_pickup_date"]').val();
            }

            if( current_form.find('input[name="ovabrw_pickoff_date"]').val() ){
                dropoff_date = current_form.find('input[name="ovabrw_pickoff_date"]').val();
            }

            if( current_form.find('input[name="ovabrw_adults"]').val() ){
                ovabrw_adults = current_form.find('input[name="ovabrw_adults"]').val();
            }

            if( current_form.find('input[name="ovabrw_childrens"]').val() ){
                ovabrw_childrens = current_form.find('input[name="ovabrw_childrens"]').val();
            }

            if( current_form.find('select[name="ovabrw_period_package_id"]').val() ){
                package_id = current_form.find('select[name="ovabrw_period_package_id"]').val();
            }
            

            if( current_form.find('input[name="ovabrw_number_vehicle"]').val() ){
                quantity = current_form.find('input[name="ovabrw_number_vehicle"]').val();
            }

            if( current_form.find('input[name="ova_type_deposit"]:checked').val() ){
                deposit = current_form.find('input[name="ova_type_deposit"]:checked').val();
            }


            // Reference the CheckBoxes and insert the checked CheckBox value in Array.
            current_form.find(".ovabrw_resource input[type=checkbox]:checked").each(function () {
                resources[$(this).data('resource_key')] = $(this).val();
            });
            
            // Convert to object
            obj_resource = $.extend({}, resources ) ;

            // Service
            current_form.find( "select[name='ovabrw_service[]']" ).each( function() {
                if( $(this).val() ){
                    services.push($(this).val());    
                }
            });
          
            obj_service = $.extend({}, services ) ;

            if ( pickup_date && dropoff_date ) {
                $.ajax({
                    url: ajax_object.ajax_url,
                    type: 'POST',
                    data: ({
                        action: 'ovabrw_calculate_total',
                        pickup_loc: pickup_loc,
                        dropoff_loc: dropoff_loc,
                        pickup_date: pickup_date,
                        package_id: package_id,
                        dropoff_date: dropoff_date,
                        adults: ovabrw_adults,
                        childrens: ovabrw_childrens,
                        quantity: quantity,
                        deposit: deposit,
                        resources: JSON.stringify( obj_resource ),
                        services: JSON.stringify( obj_service ),
                        id: id,
                    }),
                    success: function(response){
                        var data = JSON.parse(response);
                        if( response != 0 ){
                            that.find( '.show_ajax_content' ).addClass('active');
                            that.find( '.show_total' ).html('').append( data['line_total'] );    
                            if ( data['number_vehicle_available'] > 0 ) {
                                that.find( '.show_availables_vehicle' ).html('').append( data['number_vehicle_available'] );
                                that.find( 'input[name="ovabrw_number_vehicle"]' ).attr('max', data['number_vehicle_available']);
                            } else {
                                that.find( '.show_availables_vehicle' ).html('').append( 0 );
                                that.find( 'input[name="ovabrw_number_vehicle"]' ).attr('max', 1);
                            }
                        }
                        current_form.find( '.ajax_loading' ).removeClass('active');
                    },
                });
            } else {
                current_form.find( '.ajax_loading' ).removeClass('active');
            }
        }

    },

    ova_search_ajax: function() {

        if ( $('.ovabrw-search-ajax .wrap-search-ajax').length > 0 ) {
            loadAjaxSearch();
        }

        $('.ovabrw-search-ajax .wrap-search-ajax .ovabrw-btn').on('click', function(e){
            loadAjaxSearch();
            e.preventDefault();
        });

        /* Video */
        function video_popup( that ) {
            var video = that.find('.room-video-link');
            var modal_container = that.find('.modal-container');
            var modal_close     = that.find('.modal-close');
            var modal_video     = that.find('.modal-video');

            video.each( function() {
                $(this).on('click', function(){
                    var url = get_url( $(this).data('src') );
                    var option      = '?autoplay=1;&loop=1;&controls=1;&showinfo=1;&modestbranding=1';
                    if ( url != 'error' ) {
                        modal_video.attr('src', "https://www.youtube.com/embed/" + url + option );
                        modal_container.css('display', 'flex');
                    }
                });
            });

            // close video
            modal_close.on('click', function() {
                modal_container.hide();
                modal_video.removeAttr('src');
            });

            // window click
            $(window).click( function(e) {
                if ( e.target.className == 'modal-container' ) {
                    modal_container.hide();
                    modal_video.removeAttr('src');
                }
            });
        }

        function get_url( url ) {
            var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
            var match = url.match(regExp);

            if (match && match[2].length == 11) {
                return match[2];
            } else {
                return 'error';
            }
        }

        $(document).find(".wrap-search-ajax .search-ajax-result .room-video-gallery-wrapper").each( function() {
            var that = $(this);
        });

        /* Product Gallery Fancybox */
        function product_gallery_fancybox( that ) {
            var gallery     = that.find('.room-image-gallery');

            gallery.each( function() {
                var gallery_src = $(this).data('gallery-src');
                $(this).on('click', function(){
                    $.fancybox.open( gallery_src, {
                        thumbs : {
                          autoStart : true,
                          axis      : 'x'
                        }
                    });
                });
            });
        }

        $(document).find(".wrap-search-ajax .search-ajax-result .room-video-gallery-wrapper").each( function() {
            var that = $(this);
        });

        /* Pagination */
        $(document).on('click', '.wrap-search-ajax .ovabrw-pagination-ajax .page-numbers', function(e) {
            e.preventDefault();

            var that    = $(this);
            var current = $('.wrap-search-ajax .ovabrw-pagination-ajax .current').attr('data-paged');
            var paged   = that.attr('data-paged');
            if ( (current != paged) || (current == 1 && paged == 1) ) {
                $(window).scrollTop(0);
                $('.wrap-search-ajax .ovabrw-pagination-ajax .page-numbers').removeClass('current');
                that.addClass('current');
                loadAjaxSearch();
            }
        });

        // Show booking room
        $(document).on('click', '.ovabrw-search-ajax .wrap-search-ajax .room-button', function() {
            var room_btn     = $(this);
            var room_id      = room_btn.data('room-id');
            var wrap_search  = room_btn.closest('.wrap-search-ajax');
            var form         = wrap_search.find('.ovabrw-search-form');

            var start_date   = form.find('input[name="ovabrw_pickup_date"]').val();
            var end_date     = form.find('input[name="ovabrw_pickoff_date"]').val();
            var adults       = form.find('input[name="ovabrw_adults"]').val();
            var childrens    = form.find('input[name="ovabrw_childrens"]').val();
            var beds         = form.find('input[name="ovabrw_beds"]').val();
            var baths        = form.find('input[name="ovabrw_baths"]').val();
            
            var room_content = room_btn.closest('.ovabrw-room-content');
            var result       = room_content.find('.booking-room');

            result.html('');

            var data_ajax   = {
                action: 'ovabrw_show_room_booking',
                room_id: room_id,
                start_date: start_date,
                end_date: end_date,
                adults: adults,
                childrens: childrens,
                beds: beds,
                baths: baths,
            };

            $.ajax({
                url: ajax_object.ajax_url,
                type: 'POST',
                data: data_ajax,
                success:function(response) {
                    if( response ){
                        var json = JSON.parse( response );
                        var item = $(json.result).fadeOut(300).fadeIn(500);
                        room_content.find('.room-load-more').hide();

                        result.css('height', 'auto');
                        result.html(item);
                        if ( json.pickup_date ) {
                            start_date = json.pickup_date;
                        }
                        if ( json.pickoff_date ) {
                            end_date = json.pickoff_date;
                        }
                        
                        // Booking
                        result.find('input[name="ovabrw_pickup_date"]').val(start_date);
                        result.find('input[name="ovabrw_pickoff_date"]').val(end_date);
                        result.find('input[name="ovabrw_adults"]').val(adults);
                        result.find('input[name="ovabrw_childrens"]').val(childrens);
                        result.find('.gueststotal').text( parseInt(adults) + parseInt(childrens) );

                        // Request booking
                        result.find('input[name="pickup_date"]').val(start_date);
                        result.find('input[name="pickoff_date"]').val(end_date);
                        result.find('input[name="number_adults"]').val(adults);
                        result.find('input[name="number_childrens"]').val(childrens);
                        
                        bookingTab();
                        Brw_Frontend.brw_datepicker();
                        Brw_Frontend.ova_guestspicker();
                        Brw_Frontend.submit_button();
                        Brw_Frontend.ova_ajax_show_total();

                        var room_close = result.find('.modal-popup-close');
                        room_close.on('click', function() {
                            result.html('');
                        });
                    }
                },
            });

        });

        /* Booking Tabs */
        function bookingTab() {
            $('.ovabrw-search-ajax .wrap-search-ajax .forms-booking-tab').each( function() {
                var that    = $(this);
                var item    = that.find('.tabs .item');
                var booking = that.find('.romancy-booking');

                item.on( 'click', function() {
                    if ( ! $(this).hasClass('active') ) {
                        item.removeClass('active');
                        $(this).addClass('active');
                        booking.hide();
                        var form = $(this).data('form');
                        that.find('.'+$(this).data('form')).show();
                    }
                });
            });
        }

         /* load ajax search */
        function loadAjaxSearch() {
            var that        = $(document).find('.ovabrw-search-ajax .wrap-search-ajax');
            var template    = that.data('template');
            var start_date  = that.find('input[name="ovabrw_pickup_date"]').val();
            var end_date    = that.find('input[name="ovabrw_pickoff_date"]').val();
            var adults      = that.find('input[name="ovabrw_adults"]').val();
            var childrens   = that.find('input[name="ovabrw_childrens"]').val();
            var beds        = that.find('input[name="ovabrw_beds"]').val();
            var baths       = that.find('input[name="ovabrw_baths"]').val();
            var result      = that.find('.search-ajax-result');
            var order       = result.data('order');
            var orderby     = result.data('orderby');
            var per_page    = result.data('posts-per-page');
            var paged       = result.find('.ovabrw-pagination-ajax .current').attr('data-paged');

            that.find('.wrap-load-more').show();

            var data_ajax   = {
                action: 'ovabrw_search_ajax',
                template: template,
                start_date: start_date,
                end_date: end_date,
                adults: adults,
                childrens: childrens,
                beds: beds,
                baths: baths,
                order: order,
                orderby: orderby,
                per_page: per_page,
                paged: paged,
            };

            $.ajax({
                url: ajax_object.ajax_url,
                type: 'POST',
                data: data_ajax,
                success:function(response) {
                    if( response ){
                        var json = JSON.parse( response );
                        var item = $(json.result).fadeOut(300).fadeIn(500);
                        result.html(item);
                        that.find('.wrap-load-more').hide();
                        video_popup( that );
                        product_gallery_fancybox( that );
                        if ( paged != 1 ) {
                            result.find('.ovabrw-pagination-ajax .current').attr('data-paged',1);
                        }
                    }
                },
            });
        }
    },

    ova_guestspicker: function() {
        $(".ovabrw-wrapper-guestspicker").each(function(){
            var that = $(this);
            var guestspicker = that.find('.ovabrw-guestspicker');
            var guestspicker_content = that.find('.ovabrw-guestspicker-content')

            guestspicker.on('click', function() {
                guestspicker_content.toggle();
            });

            $(window).click( function(e) {
                if ( !guestspicker.is(e.target) && guestspicker.has(e.target).length === 0 && !guestspicker_content.is(e.target) && guestspicker_content.has(e.target).length === 0 ) {
                    guestspicker_content.hide();
                }
            });

            var minus = that.find('.minus');
            minus.on('click', function() {
                gueststotal($(this), 'sub');
            });

            var plus = that.find('.plus');
            plus.on('click', function() {
                gueststotal($(this), 'sum');
            });
        });

        function gueststotal( that, cal ) {
            var formBooking     = that.closest('form.booking_form');
            var guests_button   = that.closest('.guests-button');
            var input   = guests_button.find('input[type="text"]');
            var value   = input.val();
            var min     = input.attr('min');
            var max     = input.attr('max');

            if ( cal == 'sub' && parseInt(value) > parseInt(min) ) {
                input.val(parseInt(value) - 1);
                Brw_Frontend.ova_ajax_show_total_after_load(formBooking);
            }

            if ( cal == 'sum' && parseInt(value) < parseInt(max) ) {
                input.val(parseInt(value) + 1);
                Brw_Frontend.ova_ajax_show_total_after_load(formBooking);
            }

            var wrapper_guestspicker = that.closest('.ovabrw-wrapper-guestspicker');
            var adults = wrapper_guestspicker.find('.ovabrw_adults').val();
            var childrens = wrapper_guestspicker.find('.ovabrw_childrens').val();
            var gueststotal = wrapper_guestspicker.find('.gueststotal');
            if ( gueststotal ) {
                gueststotal.text( parseInt(adults) + parseInt(childrens) );
            }
        }
    }
};

/* ready */
$(document).ready(function () {

    Brw_Frontend.init();


});


/* Show Custom Taxonomy in Search Form */
function ovas_show_cus_tax( form, cat_val ){


    if( typeof ova_brw_search_show_tax_depend_cat != 'undefined' && form.length ){
        
        if( ova_brw_search_show_tax_depend_cat == 'yes' ){
            /* Hide all custom taxonomy select */
            form.find('.s_field_cus_tax').hide();
        }else{
            form.find('.s_field_cus_tax').show();
        }
    }
    

    if( cat_val ){
        $.ajax({
            url: ajax_object.ajax_url,
            type: 'POST',
            data: ({
               action: 'ovabrw_get_tax_in_cat',
               cat_val: cat_val,
            }),
            success:function(response) {

                if( response ){

                    var list_tax_values = response.split(",");

                    if( list_tax_values.length ){
                        for (var i = 0; i < list_tax_values.length; i++) {
                            if( form.find("."+list_tax_values[i]).length > 0 ){
                                form.find("."+list_tax_values[i]).show();
                            }
                            
                        }
                    }

                }
              
            },

        });
    }

    

}

 

document.addEventListener('DOMContentLoaded', function() {

    var date_format = '';
    if(typeof brw_date_format !== 'undefined' ){
        date_format = brw_date_format;
    }
    
    $('.wrap_calendar').each( function( e){

        var id = $(this).data('id');

        var srcCalendarEl = $(this).find('.ovabrw__product_calendar')[0];

        if( srcCalendarEl === null ) return;

        var nav = srcCalendarEl.getAttribute('data-nav');
        var default_view = srcCalendarEl.getAttribute('data-default_view');
        var first_day = srcCalendarEl.getAttribute('data-first-day');
        if ( !first_day ) {
            first_day = 0;
        }

        if( default_view == 'month' ){
            default_view = 'dayGridMonth';
        }
        
        var cal_lang = srcCalendarEl.getAttribute( 'data-lang' ).replace(/\s/g, '');
        var define_day = srcCalendarEl.getAttribute('data-define_day');

        var data_event_number = parseInt( srcCalendarEl.getAttribute('data_event_number') );

        var default_hour_start = srcCalendarEl.getAttribute( 'default_hour_start' );

        var time_to_book_start = srcCalendarEl.getAttribute('time_to_book_start');
        time_to_book_start = Brw_Frontend.ova_get_time_to_book_start(time_to_book_start);

        var price_calendar = srcCalendarEl.getAttribute('price_calendar');
        var price_full_calendar_value = JSON.parse( price_calendar );

        var special_time = srcCalendarEl.getAttribute('data-special-time');
        var special_time_value = JSON.parse( special_time );
        
        var background_day = srcCalendarEl.getAttribute('data-background-day');
        var disable_week_day = srcCalendarEl.getAttribute('data-disable_week_day');
        var disable_week_day_value = '';
        if( disable_week_day ) {
            disable_week_day_value = JSON.parse( disable_week_day );
        }

        var events = '';
        var date_rent_full = [];
        var order_time = srcCalendarEl.getAttribute('order_time');
        if( order_time && order_time.length > 0 ){
            events = JSON.parse( order_time );
        }

        if (typeof events !== 'undefined' && events.length > 0) {
            events.forEach(function(item, index){
                if( item.hasOwnProperty('rendering') ) {

                    date_rent_full.push( item.start );

                }
            });
        }
        
        var srcCalendar = new FullCalendar.Calendar(srcCalendarEl, {

            editable: false,
            events: events,
            firstDay: first_day,
            height: 'auto',
            headerToolbar: {
                left: 'prev,next,today,' + nav,
                right: 'title',
            },
            initialView: default_view,
            locale: cal_lang,

            dayMaxEventRows: true, // for all non-TimeGrid views
              views: {
               dayGrid: {
                    dayMaxEventRows: data_event_number
                  // options apply to dayGridMonth, dayGridWeek, and dayGridDay views
                },
                timeGrid: {
                    dayMaxEventRows: data_event_number
                  // options apply to timeGridWeek and timeGridDay views
                },
                week: {
                    dayMaxEventRows: data_event_number
                  // options apply to dayGridWeek and timeGridWeek views
                },
                day: {
                    dayMaxEventRows: data_event_number
                  // options apply to dayGridDay and timeGridDay views
                }
            },

            dayCellDidMount: function( e ){

                var new_date    = new Date( e.date );
                var time_stamp  = Date.UTC( new_date.getFullYear(), new_date.getMonth(), new_date.getDate() )/1000;
                
                if( price_full_calendar_value != '' ) {
                    var type_price = price_full_calendar_value[0].type_price;
                    if( type_price == 'day' ) {
                        var ovabrw_daily_monday = price_full_calendar_value[1].ovabrw_daily_monday;
                        var ovabrw_daily_tuesday = price_full_calendar_value[1].ovabrw_daily_tuesday;
                        var ovabrw_daily_wednesday = price_full_calendar_value[1].ovabrw_daily_wednesday;
                        var ovabrw_daily_thursday = price_full_calendar_value[1].ovabrw_daily_thursday;
                        var ovabrw_daily_friday = price_full_calendar_value[1].ovabrw_daily_friday;
                        var ovabrw_daily_saturday = price_full_calendar_value[1].ovabrw_daily_saturday;
                        var ovabrw_daily_sunday = price_full_calendar_value[1].ovabrw_daily_sunday;

                        switch( new_date.getDay() ) {
                            case 0 : {

                                // check disable week day in settings
                                if ( disable_week_day_value ) {
                                    $.each( disable_week_day_value, function( key, day_value ) {
                                        if( day_value == new_date.getDay() ) {
                                            e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                            // set background day
                                            $('.unavailable_date').css('background-color', background_day);
                                        }
                                    });
                                }
                                
                                let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];
                                if( el ){
                                    if ( special_time_value ) {
                                        el.innerHTML = ovabrw_daily_sunday;
                                        $.each( special_time_value, function( key, data_special ) {
                                            if ( time_stamp >= data_special['from'] && time_stamp <= data_special['to'] ) {
                                                el.innerHTML = data_special['price'];
                                            }
                                        });
                                    } else {
                                        el.innerHTML = ovabrw_daily_sunday;
                                    }
                                    
                                    return e;    
                                }
                                
                                break;
                            }
                            case 1 : {

                                // check disable week day in settings
                                if ( disable_week_day_value ) {
                                    $.each( disable_week_day_value, function( key, day_value ) {
                                        if( day_value == new_date.getDay() ) {
                                            e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                            // set background day
                                            $('.unavailable_date').css('background-color', background_day);
                                        }
                                    });
                                }

                                let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];
                                if( el ){
                                    
                                    if ( special_time_value ) {
                                        el.innerHTML = ovabrw_daily_monday;
                                        $.each( special_time_value, function( key, data_special ) {
                                            if ( time_stamp >= data_special['from'] && time_stamp <= data_special['to'] ) {
                                                el.innerHTML = data_special['price'];
                                            }
                                        });
                                    } else {
                                        el.innerHTML = ovabrw_daily_monday;
                                    }

                                    return e;
                                }
                                
                                break;
                            }
                            case 2 : {

                                // check disable week day in settings
                                if ( disable_week_day_value ) {
                                    $.each( disable_week_day_value, function( key, day_value ) {
                                        if( day_value == new_date.getDay() ) {
                                            e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                            // set background day
                                            $('.unavailable_date').css('background-color', background_day);
                                        }
                                    });
                                }

                                let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];
                                if( el ){

                                    if ( special_time_value ) {
                                        el.innerHTML = ovabrw_daily_tuesday;
                                        $.each( special_time_value, function( key, data_special ) {
                                            if ( time_stamp >= data_special['from'] && time_stamp <= data_special['to'] ) {
                                                el.innerHTML = data_special['price'];
                                            }
                                        });
                                    } else {
                                        el.innerHTML = ovabrw_daily_tuesday;
                                    }
                                    
                                    return e;
                                }
                                break;
                            }
                            case 3 : {

                                // check disable week day in settings
                                if ( disable_week_day_value ) {
                                    $.each( disable_week_day_value, function( key, day_value ) {
                                        if( day_value == new_date.getDay() ) {
                                            e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                            // set background day
                                            $('.unavailable_date').css('background-color', background_day);
                                        }
                                    });
                                }

                                let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];
                                if( el ){

                                    if ( special_time_value ) {
                                        el.innerHTML = ovabrw_daily_wednesday;
                                        $.each( special_time_value, function( key, data_special ) {
                                            if ( time_stamp >= data_special['from'] && time_stamp <= data_special['to'] ) {
                                                el.innerHTML = data_special['price'];
                                            }
                                        });
                                    } else {
                                        el.innerHTML = ovabrw_daily_wednesday;
                                    }
                                    
                                    return e;
                                }
                                break;
                            }
                            case 4 : {

                                // check disable week day in settings
                                if ( disable_week_day_value ) {
                                    $.each( disable_week_day_value, function( key, day_value ) {
                                        if( day_value == new_date.getDay() ) {
                                            e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                            // set background day
                                            $('.unavailable_date').css('background-color', background_day);
                                        }
                                    });
                                }

                                let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];
                                if( el ){

                                    if ( special_time_value ) {
                                        el.innerHTML = ovabrw_daily_thursday;
                                        $.each( special_time_value, function( key, data_special ) {
                                            if ( time_stamp >= data_special['from'] && time_stamp <= data_special['to'] ) {
                                                el.innerHTML = data_special['price'];
                                            }
                                        });
                                    } else {
                                        el.innerHTML = ovabrw_daily_thursday;
                                    }

                                    return e;
                                }
                                break;
                            }
                            case 5 : {

                                // check disable week day in settings
                                if ( disable_week_day_value ) {
                                    $.each( disable_week_day_value, function( key, day_value ) {
                                        if( day_value == new_date.getDay() ) {
                                            e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                            // set background day
                                            $('.unavailable_date').css('background-color', background_day);
                                        }
                                    });
                                }

                                let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];
                                if( el ){

                                    if ( special_time_value ) {
                                        el.innerHTML = ovabrw_daily_friday;
                                        $.each( special_time_value, function( key, data_special ) {
                                            if ( time_stamp >= data_special['from'] && time_stamp <= data_special['to'] ) {
                                                el.innerHTML = data_special['price'];
                                            }
                                        });
                                    } else {
                                        el.innerHTML = ovabrw_daily_friday;
                                    }
                                    
                                    return e;
                                }
                                break;
                            }
                            case 6 : {

                                // check disable week day in settings
                                if ( disable_week_day_value ) {
                                    $.each( disable_week_day_value, function( key, day_value ) {
                                        if( day_value == new_date.getDay() ) {
                                            e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                            // set background day
                                            $('.unavailable_date').css('background-color', background_day);
                                        }
                                    });
                                }

                                let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];
                                if( el ){

                                    if ( special_time_value ) {
                                        el.innerHTML = ovabrw_daily_saturday;
                                        $.each( special_time_value, function( key, data_special ) {
                                            if ( time_stamp >= data_special['from'] && time_stamp <= data_special['to'] ) {
                                                el.innerHTML = data_special['price'];
                                            }
                                        });
                                    } else {
                                        el.innerHTML = ovabrw_daily_saturday;
                                    }
                                    
                                    return e;
                                }
                                break;
                            }

                        }


                    } else if( type_price == 'hour' ) {

                        // check disable week day in settings
                        if ( disable_week_day_value ) {
                            $.each( disable_week_day_value, function( key, day_value ) {
                                if( day_value == new_date.getDay() ) {
                                    e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                    // set background day
                                    $('.unavailable_date').css('background-color', background_day);
                                }
                            });
                        }

                        var ovabrw_price_hour = price_full_calendar_value[1].ovabrw_price_hour;
                        let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];
                        if( el ){
                            if ( special_time_value ) {
                                el.innerHTML = ovabrw_price_hour;
                                $.each( special_time_value, function( key, data_special ) {
                                    if ( time_stamp >= data_special['from'] && time_stamp <= data_special['to'] ) {
                                        el.innerHTML = data_special['price'];
                                    }
                                });
                            } else {
                                el.innerHTML = ovabrw_price_hour;
                            }
                            
                            return e;
                        }
                    } else if( type_price == 'mixed' ) {
                        var ovabrw_daily_monday = price_full_calendar_value[1].ovabrw_daily_monday;
                        var ovabrw_daily_tuesday = price_full_calendar_value[1].ovabrw_daily_tuesday;
                        var ovabrw_daily_wednesday = price_full_calendar_value[1].ovabrw_daily_wednesday;
                        var ovabrw_daily_thursday = price_full_calendar_value[1].ovabrw_daily_thursday;
                        var ovabrw_daily_friday = price_full_calendar_value[1].ovabrw_daily_friday;
                        var ovabrw_daily_saturday = price_full_calendar_value[1].ovabrw_daily_saturday;
                        var ovabrw_daily_sunday = price_full_calendar_value[1].ovabrw_daily_sunday;

                        switch( new_date.getDay() ) {
                            case 0 : {

                                // check disable week day in settings
                                if ( disable_week_day_value ) {
                                    $.each( disable_week_day_value, function( key, day_value ) {
                                        if( day_value == new_date.getDay() ) {
                                            e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                            // set background day
                                            $('.unavailable_date').css('background-color', background_day);
                                        }
                                    });
                                }
                                
                                let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];
                                if( el ){
                                    if ( special_time_value ) {
                                        el.innerHTML = ovabrw_daily_sunday;
                                        $.each( special_time_value, function( key, data_special ) {
                                            if ( time_stamp >= data_special['from'] && time_stamp <= data_special['to'] ) {
                                                el.innerHTML = data_special['price'];
                                            }
                                        });
                                    } else {
                                        el.innerHTML = ovabrw_daily_sunday;
                                    }
                                    
                                    return e;    
                                }
                                
                                break;
                            }
                            case 1 : {

                                // check disable week day in settings
                                if ( disable_week_day_value ) {
                                    $.each( disable_week_day_value, function( key, day_value ) {
                                        if( day_value == new_date.getDay() ) {
                                            e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                            // set background day
                                            $('.unavailable_date').css('background-color', background_day);
                                        }
                                    });
                                }

                                let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];
                                if( el ){
                                    
                                    if ( special_time_value ) {
                                        el.innerHTML = ovabrw_daily_monday;
                                        $.each( special_time_value, function( key, data_special ) {
                                            if ( time_stamp >= data_special['from'] && time_stamp <= data_special['to'] ) {
                                                el.innerHTML = data_special['price'];
                                            }
                                        });
                                    } else {
                                        el.innerHTML = ovabrw_daily_monday;
                                    }

                                    return e;
                                }
                                
                                break;
                            }
                            case 2 : {

                                // check disable week day in settings
                                if ( disable_week_day_value ) {
                                    $.each( disable_week_day_value, function( key, day_value ) {
                                        if( day_value == new_date.getDay() ) {
                                            e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                            // set background day
                                            $('.unavailable_date').css('background-color', background_day);
                                        }
                                    });
                                }

                                let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];
                                if( el ){

                                    if ( special_time_value ) {
                                        el.innerHTML = ovabrw_daily_tuesday;
                                        $.each( special_time_value, function( key, data_special ) {
                                            if ( time_stamp >= data_special['from'] && time_stamp <= data_special['to'] ) {
                                                el.innerHTML = data_special['price'];
                                            }
                                        });
                                    } else {
                                        el.innerHTML = ovabrw_daily_tuesday;
                                    }
                                    
                                    return e;
                                }
                                break;
                            }
                            case 3 : {

                                // check disable week day in settings
                                if ( disable_week_day_value ) {
                                    $.each( disable_week_day_value, function( key, day_value ) {
                                        if( day_value == new_date.getDay() ) {
                                            e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                            // set background day
                                            $('.unavailable_date').css('background-color', background_day);
                                        }
                                    });
                                }

                                let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];
                                if( el ){

                                    if ( special_time_value ) {
                                        el.innerHTML = ovabrw_daily_wednesday;
                                        $.each( special_time_value, function( key, data_special ) {
                                            if ( time_stamp >= data_special['from'] && time_stamp <= data_special['to'] ) {
                                                el.innerHTML = data_special['price'];
                                            }
                                        });
                                    } else {
                                        el.innerHTML = ovabrw_daily_wednesday;
                                    }
                                    
                                    return e;
                                }
                                break;
                            }
                            case 4 : {

                                // check disable week day in settings
                                if ( disable_week_day_value ) {
                                    $.each( disable_week_day_value, function( key, day_value ) {
                                        if( day_value == new_date.getDay() ) {
                                            e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                            // set background day
                                            $('.unavailable_date').css('background-color', background_day);
                                        }
                                    });
                                }

                                let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];
                                if( el ){

                                    if ( special_time_value ) {
                                        el.innerHTML = ovabrw_daily_thursday;
                                        $.each( special_time_value, function( key, data_special ) {
                                            if ( time_stamp >= data_special['from'] && time_stamp <= data_special['to'] ) {
                                                el.innerHTML = data_special['price'];
                                            }
                                        });
                                    } else {
                                        el.innerHTML = ovabrw_daily_thursday;
                                    }

                                    return e;
                                }
                                break;
                            }
                            case 5 : {

                                // check disable week day in settings
                                if ( disable_week_day_value ) {
                                    $.each( disable_week_day_value, function( key, day_value ) {
                                        if( day_value == new_date.getDay() ) {
                                            e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                            // set background day
                                            $('.unavailable_date').css('background-color', background_day);
                                        }
                                    });
                                }

                                let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];
                                if( el ){

                                    if ( special_time_value ) {
                                        el.innerHTML = ovabrw_daily_friday;
                                        $.each( special_time_value, function( key, data_special ) {
                                            if ( time_stamp >= data_special['from'] && time_stamp <= data_special['to'] ) {
                                                el.innerHTML = data_special['price'];
                                            }
                                        });
                                    } else {
                                        el.innerHTML = ovabrw_daily_friday;
                                    }
                                    
                                    return e;
                                }
                                break;
                            }
                            case 6 : {

                                // check disable week day in settings
                                if ( disable_week_day_value ) {
                                    $.each( disable_week_day_value, function( key, day_value ) {
                                        if( day_value == new_date.getDay() ) {
                                            e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                            // set background day
                                            $('.unavailable_date').css('background-color', background_day);
                                        }
                                    });
                                }

                                let el = e.el.querySelectorAll(".fc-daygrid-day-bg")[0];
                                if( el ){

                                    if ( special_time_value ) {
                                        el.innerHTML = ovabrw_daily_saturday;
                                        $.each( special_time_value, function( key, data_special ) {
                                            if ( time_stamp >= data_special['from'] && time_stamp <= data_special['to'] ) {
                                                el.innerHTML = data_special['price'];
                                            }
                                        });
                                    } else {
                                        el.innerHTML = ovabrw_daily_saturday;
                                    }
                                    
                                    return e;
                                }
                                break;
                            }
                        }
                    }
                } else {
                    var type_price = srcCalendarEl.getAttribute('type_price');
                    // period_time
                    if( type_price == 'period_time' || type_price == 'transportation' ) {

                        // check disable week day in settings
                        if ( disable_week_day_value ) {
                            $.each( disable_week_day_value, function( key, day_value ) {
                                if( day_value == new_date.getDay() ) {
                                    e.el.children[0].className = e.el.children[0].className + ' unavailable_date';
                                    // set background day
                                    $('.unavailable_date').css('background-color', background_day);
                                }
                            });
                        }

                        return e;
                    }
                }
            },

            dateClick: function( info ) {
                var new_date_a = new Date( info.date );            

                var year  = new_date_a.getFullYear();
                var month = new_date_a.getMonth() + 1;
                var day   = new_date_a.getDate();

                month = (month < 10) ? '0' + month : month;
                day = (day < 10) ? '0' + day : day;

                var today = new Date();
                var date_click_24 = new Date( year, month - 1, day, 24, 0 );

                var date_check_rent_full = year + '-' + month + '-' + day;


                if( define_day == "hotel" || $('.rental_item input[name="ovabrw_pickup_date"]').hasClass('no_time_picker') || (time_to_book_start.length < 1 ) ) {
                    default_hour_start = '';
                }

                // check disable week day in settings
                if ( disable_week_day_value ) {
                    $.each( disable_week_day_value, function( key, day_value ) {
                        if( day_value == new_date_a.getDay() ) {
                            alert(notifi_disable_day);
                            date_click_24 = 0;
                        }
                    });
                }

                if( ( ! date_rent_full.includes( date_check_rent_full ) ) && ( date_click_24 >= today )  ) {
                    var date_input = date_format;
                    date_input = date_input.replace('Y', year);
                    date_input = date_input.replace('m', month);
                    date_input = date_input.replace('d', day);

                    $('input[name="ovabrw_pickup_date"]').val(date_input + ' ' + default_hour_start);
                    if( document.getElementById("ovabrw_booking_form") ){
                        document.getElementById("ovabrw_booking_form").scrollIntoView({behavior: 'smooth'});
                    }
                }
            }
       

        });

        srcCalendar.render();


    } );

    
    
}); 


})(jQuery);
